import React, { useState } from "react";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_INVOICE_MANAGER
} from "../../roles";
import { useIntl } from "react-intl";
import {
  useAgreements,
  useLimitChangeClaims,
  useMutator
} from "../../hooks/useApi";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";
import PageLayout from "../../components/PageLayout";
import purpleHourGlass from "../../images/purpleHourGlass.svg";
import redHourGlass from "../../images/redHourGlass.svg";
import smallGreenTick from "../../images/redesignImages/acceptSvg.svg";
import { useHistory } from "react-router-dom";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { getStoredToken } from "../../utils/authentication";
import rejected from "../../images/redesignImages/Rejected.svg"
import DocumentsIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import {
  Approved,
  Granted,
  OnHold,
  Rejected,
  WaitingForBoConfirmation,
  WaitingForCustomerConfirmation,
  WaitingForCustomerSignature,
  WaitingForSupplierSignature
} from "../InvoicePage/InvoiceStatusIcons";
import closedSvg from "../../images/redesignImages/Rejected.svg";
import InvoiceStatusHelperDialog from "../InvoicePage/InvoiceStatusHelperDialog";

export const makeLimitStatusIconRenderer = () => ({ value, data }) => {
  const getStatusIcon = () => {
    switch (value) {
      case "CLAIM":
      case "DECISION":
        return (
          <>
            <img
              style={{ marginLeft: "6px", marginRight: "16px" }}
              src={purpleHourGlass}
            />
            <Typography>Feldolgozás alatt</Typography>
          </>
        );
      case "CLOSED":
      case "DONE":
        return (
          <>
            <img
              style={{ width: "22px", marginRight: "10px" }}
              src={smallGreenTick}
            />
            <Typography>A folyamat lezárult</Typography>
          </>
        );
      case "DOCUMENT_UPLOAD":
        return (
          <>
            <img
              style={{ marginLeft: "6px", marginRight: "16px" }}
              src={redHourGlass}
            />
            <Typography>Feladatod van vele</Typography>
          </>
        );
      case "PRE_RISK_EVALUATION":
        return (
          <>
            <img
              style={{ marginLeft: "6px", marginRight: "16px" }}
              src={purpleHourGlass}
            />
            <Typography>Dokumentum feltöltés folyamatban</Typography>
          </>
        );
      case "DECLINED":
        return (
          <>
            <img
              style={{ width: "22px",height: "22px", marginRight: "10px" }}
              src={rejected}
            />
            <Typography>Lezárva</Typography>
          </>
        );
      default:
        return "";
    }
  };

  return <span style={{ display: "inline-flex" }}>{getStatusIcon()}</span>;
};

const LimitChangeListPage = ({}) => {
  const LimitChangeList = ({ hideSearchBar }) => {
    const hasPermission = useHasPermission();
    const isInvoiceManager = hasPermission([ROLE_FLEXIPAY_INVOICE_MANAGER]);
    const intl = useIntl();
    const { data: agreements, isValidating: loading } = useLimitChangeClaims();
    const [get] = useMutator("GET");
    const getColumns = intl => {
      const currencyFormatter = makeCurrencyValueFormatter(intl);
      return [
        {
          headerName: "Vevő neve",
          field: "customerName",
          sortable: true,
          autoHeight: true,
          resizable: true,
          mobileCellXs: 12,
          cellStyle: ({ data }) => {
            if (data.suspended === true) {
              return { opacity: "0.4", pointerEvents: "none" };
            }
          }
        },
        {
          headerName: "Deviza",
          field: "currency",
          sortable: true,
          autoHeight: true
        },
        {
          headerName: "Limit",
          field: "limit",
          valueFormatter: currencyFormatter,
          sortable: true,
          autoHeight: true,
          cellClass: "cell-wrap-text",
          mobileCellXs: 12,
          cellStyle: ({ data }) => {
            if (data.suspended === true) {
              return { opacity: "0.4", pointerEvents: "none" };
            }
          },
          cellRendererFramework: ({ data, value }) => {
            return (
              <Tooltip
                placement={"top"}
                title={
                  data.hasLimitAgreementClaim
                    ? "Limit módosítás folyamatban"
                    : ""
                }
              >
                <Grid container>
                  {!isInvoiceManager && (
                    <Grid item>
                      {/*<MinusButton*/}
                      {/*  disabled={data.hasLimitAgreementClaim}*/}
                      {/*  onClick={() => minusButtonClick(data)}*/}
                      {/*>*/}
                      {/*  <Typography style={{ color: "#EE605D" }}>-</Typography>*/}
                      {/*</MinusButton>*/}
                    </Grid>
                  )}
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      width: "100px",
                      alignSelf: "center"
                    }}
                  >
                    <Typography>
                      {currencyFormatter({ value, data })}
                    </Typography>
                  </Grid>
                  {!isInvoiceManager && (
                    <Grid item>
                      {/*<PlusButton*/}
                      {/*  disabled={data.hasLimitAgreementClaim}*/}
                      {/*  onClick={() => plusButtonClick(data)}*/}
                      {/*>*/}
                      {/*  <Typography style={{ color: "#a6d170" }}>+</Typography>*/}
                      {/*</PlusButton>*/}
                    </Grid>
                  )}
                </Grid>
              </Tooltip>
            );
          }
        },
        {
          headerName: "Módosított limit",
          field: "modifiedLimit",
          valueFormatter: currencyFormatter,
          sortable: true,
          autoHeight: true,
          cellClass: "cell-wrap-text",
          mobileCellXs: 12,
          cellStyle: ({ data }) => {
            if (data.suspended === true) {
              return { opacity: "0.4", pointerEvents: "none" };
            }
          }
        },
        {
          headerName: "Státusz",
          sortable: true,
          autoHeight: true,
          field: "status",
          cellRendererFramework: makeLimitStatusIconRenderer()
        },
        {
          headerName: "",
          field: "id",
          sortable: true,
          cellRendererFramework: ({ value, data }) => {
            if (data.status === "DOCUMENT_UPLOAD") {
              return (
                <Button
                  variant={"text"}
                  onClick={() =>
                    redirect(`/app/limit-change-file-upload/${value}`)
                  }
                >
                  Tovább
                </Button>
              );
            }
            return null
          },
          width: 250,
          cellStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }
        }
      ];
    };

    const columns = getColumns(intl);
    return (
      <>
        {agreements && (
          <FlexiExpandableRemoteList
            columnDefs={Object.keys(columns).map(key => columns[key])}
            url={getUrlWithCompanyId("/limitChangeClaim")}
            token={getStoredToken()}
            suppressExpand
            hideSearchBar={hideSearchBar}
            hidePager={hideSearchBar}
          />
        )}
      </>
    );
  };
  const { push: redirect } = useHistory();
  return (
    <PageLayout
      insidePage
      pay
      suppressTourButton
      title="Limitmódosítás"
      rightSideComponents={[
        {
          props: {
            onClick: () => redirect("/app/limit-change"),
            children: "Limitmódosítás"
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ]}
    >
      <LimitChangeList />
    </PageLayout>
  );
};

export default LimitChangeListPage;
