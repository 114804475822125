import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import billChair from "../../images/bill/new/bill-chair.svg";
import billChairOrange from "../../images/szamlazzhu/sittingBillOrangeBg.svg";

export const Row = ({ elements, highlighted, label }) => {
  const list = Object.keys(elements).map((labelKey, index) => {
    return (
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: highlighted ? "rgba(18, 94, 242, 0.07)" : undefined
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"60px"}
          padding={"0 8px"}
          margin={"0 32px"}
          borderTop={!highlighted && "1px solid rgb(17 26 41 / 10%)"}
          marginLeft={elements[labelKey].sub ? "48px" : undefined}
        >
          <Tooltip
            placement={"bottom-start"}
            title={elements[labelKey].help ? elements[labelKey].help : ""}
          >
            <span>
              <Typography
                variant={
                  highlighted && !elements[labelKey].sub ? "h6" : "body1"
                }
              >
                {elements[labelKey].label}
              </Typography>
            </span>
          </Tooltip>
          <Typography
            variant={
              highlighted && !elements[labelKey].sub ? "caption" : "body1"
            }
            align={"right"}
          >
            {elements[labelKey].value}
          </Typography>
        </Box>
      </Grid>
    );
  });

  return label ? (
    <React.Fragment>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"60px"}
        padding={"0 8px"}
        margin={"0 32px"}
        width={"100%"}
      >
        <Typography>{label}</Typography>
      </Box>
      {list}
    </React.Fragment>
  ) : (
    list
  );
};

const DetailedRow = ({ elements, highlighted, label }) => {
  const list = Object.keys(elements).map(labelKey => {
    const data = elements[labelKey];
    return Array.isArray(data) ? (
      <DetailedRow elements={data} />
    ) : (
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: highlighted ? "rgba(18, 94, 242, 0.07)" : undefined
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          minHeight={"60px"}
          padding={"0 8px"}
          margin={"0 32px"}
          borderTop={!highlighted && "1px solid rgb(17 26 41 / 10%)"}
          marginLeft={elements[labelKey].sub ? "48px" : undefined}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography
                variant={
                  highlighted && !elements[labelKey].sub ? "h6" : "body1"
                }
              >
                {elements[labelKey].label}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <Typography
                variant={
                  highlighted && !elements[labelKey].sub ? "caption" : "body1"
                }
              >
                {elements[labelKey].value}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {elements[labelKey].help ? elements[labelKey].help : ""}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      // <Grid
      //   container
      //   style={{
      //     backgroundColor: highlighted ? "#D9E8F7" : undefined
      //   }}
      // >
      //   <Grid
      //     xs={4}
      //     alignItems={"center"}
      //     marginLeft={elements[labelKey].sub ? "1rem" : undefined}
      //     style={{
      //       borderRight: "1px solid #979797",
      //       borderLeft: "1px solid #979797",
      //       borderBottom: "1px solid #979797",
      //       borderTop: index === 0 ? "1px solid #979797" : undefined,
      //       paddingLeft: 10,
      //       paddingTop: 5,
      //       paddingBottom: 5
      //     }}
      //   >
      //     <Typography bold={highlighted && !elements[labelKey].sub}>
      //       {elements[labelKey].label}
      //     </Typography>
      //   </Grid>
      //   <Grid
      //     item
      //     xs={3}
      //     style={{
      //       borderRight: "1px solid #979797",
      //       borderBottom: "1px solid #979797",
      //       borderTop: index === 0 ? "1px solid #979797" : undefined,
      //       paddingTop: 5,
      //       paddingBottom: 5
      //     }}
      //   >
      //     <Typography
      //       bold={highlighted && !elements[labelKey].sub}
      //       align={"center"}
      //     >
      //       {elements[labelKey].value}
      //     </Typography>
      //   </Grid>
      //   <Grid
      //     item
      //     xs
      //     style={{
      //       flexGrow: 1,
      //       borderRight: "1px solid #979797",
      //       borderBottom: "1px solid #979797",
      //       borderTop: index === 0 ? "1px solid #979797" : undefined,
      //       paddingLeft: 10,
      //       paddingRight: 10,
      //       paddingTop: 5,
      //       paddingBottom: 5
      //     }}
      //   >
      //     <Typography>
      //       {elements[labelKey].help ? elements[labelKey].help : ""}
      //     </Typography>
      //   </Grid>
      // </Grid>
    );
  });

  return label ? (
    <React.Fragment>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"60px"}
        padding={"0 8px"}
        margin={"0 32px"}
        width={"100%"}
      >
        <Typography>{label}</Typography>
      </Box>
      {list}
    </React.Fragment>
  ) : (
    list
  );
};

export const ClaimsReviewItems = ({
  data,
  showDetailedCalculation,
  itemRenderer
}) => {
  return data.map(company => {
    const item = (
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container>
            <Grid
              xs={12}
              item
              alignItems={"center"}
              style={{ display: "flex", paddingBottom: "24px" }}
            >
              <Typography variant="h3" style={{ padding: "32px 40px 6px" }}>
                {company.party.partyName}
              </Typography>
            </Grid>

            {!company.calculation ? (
              <div style={{ width: "100%" }}>
                <FlexiInfoBar warning>
                  <Typography>
                    Körültekintően megvizsgáltuk faktorálási kérelmedet.
                    Sajnálattal értesítünk, hogy az ügylet nem került
                    jóváhagyásra. Ezzel a vevőddel kapcsolatban nem fogunk tudni
                    szerződni és faktorálást indítani.
                  </Typography>
                </FlexiInfoBar>
              </div>
            ) : (
              company.calculation?.[
                showDetailedCalculation
                  ? "detailedCalculationViewData"
                  : "simpleCalculationViewData"
              ]?.map(item =>
                showDetailedCalculation ? (
                  <DetailedRow {...item} />
                ) : (
                  <Row {...item} />
                )
              )
            )}
          </Grid>
        </Box>
      </Grid>
    );

    if (itemRenderer) {
      return itemRenderer(item);
    }

    return item;
  });
};

const ClaimsReview = ({
  data,
  showDetailedCalculation,
  onCallBackClick,
  onNextClick,
  renderActionButtons,
  szamlazzHu
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={showDetailedCalculation ? 12 : 7}>
        <Box>
          {showDetailedCalculation && (
            <>
              <Typography variant={"h6"} style={{ paddingBottom: "6px" }}>
                Kedves{" "}
                {data?.supplier?.party?.registrationName ||
                  data?.supplier?.party?.partyName}{" "}
                !
              </Typography>
              <Typography style={{ paddingBottom: "12px" }}>
                Megbeszélésünkre hivatkozva, küldöm (belföldi és/vagy export)
                faktoring szolgáltatásra vonatkozó indikatív (tájékoztató
                jellegű) ajánlatunkat.
              </Typography>
            </>
          )}
          <Paper style={{ padding: 0 }}>
            <ClaimsReviewItems
              data={data.customers}
              showDetailedCalculation={showDetailedCalculation}
            />
          </Paper>

          {showDetailedCalculation && (
            <>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                style={{ marginBottom: "1rem", marginTop: "2rem" }}
              >
                <Grid item>
                  <Button variant={"outlined"} onClick={onCallBackClick}>
                    Visszahívást kérek
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={onNextClick}>Tovább</Button>
                </Grid>
              </Grid>
              <Typography
                bold
                style={{ paddingTop: "12px", fontStyle: "italic" }}
              >
                A fent megadott kondíciók{" "}
                <b>
                  {(
                    data.customers[0].calculation.calculatedTotalFactorFee * 100
                  ).toFixed(2)}
                  %
                </b>{" "}
                összköltséget jelentenek a bruttó számlára vetítve a megadott
                futamidőre.
              </Typography>
              <Typography style={{ paddingTop: "6px", fontStyle: "italic" }}>
                Jelen ajánlatunk érvényességi ideje: 15 nap
              </Typography>
              <Typography style={{ paddingTop: "6px" }}>
                Budapest, {data.createdAt}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      {!showDetailedCalculation && (
        <Grid item md={5}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            style={{ marginBottom: "40px", marginTop: "40px" }}
          >
            {renderActionButtons ? (
              renderActionButtons()
            ) : (
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item>
                  <Button variant={"outlined"} onClick={onCallBackClick}>
                    Visszahívást kérek
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={onNextClick}>Tovább</Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={"center"}>
                    * Az itt közölt adatok és árak egy átlagos kockázatú ügylet
                    esetében érvényesek és tájékoztató jellegűek, nem minősülnek
                    szerződéses ajánlattételnek.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                alt={"billChair"}
                src={szamlazzHu ? billChairOrange : billChair}
                style={{
                  marginTop: "40px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "314px"
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" />
        </Grid>
      )}
    </Grid>
  );
};

export default ClaimsReview;
