import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import calcSvg from "../../images/calc.svg";
import CalculatorForm from "./CalculatorForm";
import { useProfileData, useSzamlazzhuCustomers } from "../../hooks/useApi";
import withStyles from "@material-ui/core/styles/withStyles";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";

const StyledPaper = withStyles(theme => {
  return {
    root: {
      position: "relative",
      marginTop: "30px",
      "&:before": {
        position: "absolute",
        backgroundColor: props => `url(${props.calcImg})`,
        left: "calc(50% - 30px)",
        top: "-30px",
        width: "60px",
        height: "60px",
        content: ""
      }
    }
  };
})(Paper);

const useCalculatorInitialValues = szamlazzHu => {
  const [initialValues, setInitialValues] = useState([]);
  if (szamlazzHu) {
    Promise.resolve([
      {
        calculation: {
          natureOfTransaction: "CASE_BY_CASE",
          needInsurance: "true",
          currency: "HUF"
        },
        localBusiness: "true",
        party: {
          partyName: "FOO Kft.",
          companyLegalForm: "006",
          taxNumber: "24768191-2-02",
          email: "",
          telephone: "",
          registrationAddress: {
            country: "HU",
            postalZone: "7677",
            cityName: "Orfű",
            addressLine: "Széchenyi tér 1."
          },
          postalAddress: {
            country: "HU",
            postalZone: "7677",
            cityName: "Orfű",
            addressLine: "Széchenyi tér 1."
          }
        }
      }
    ]).then(setInitialValues);
  }

  return initialValues;
};

const Calculator = ({ onSubmit }) => {
  const { szamlazzHu } = useProfileData();
  const { data: szamlazzhuCustomers = [] } = useSzamlazzhuCustomers(szamlazzHu);
  const initialValues = useMemo(
    () => ({
      companies: [
        {
          calculation: {
            natureOfTransaction: "CASE_BY_CASE",
            needInsurance: "true",
            currency: "HUF"
          },
          localBusiness: "true"
        }
      ]
    }),
    [szamlazzHu, szamlazzhuCustomers]
  );
  return (
    <StyledPaper calcImg={calcSvg}>
      <Box p={3} pt={5}>
        <Grid container spacing={2}>
          <Grid xs={12} item style={{ marginBottom: "1rem" }}>
            <Typography variant="h5">Faktoring kalkulátor</Typography>
            <br />
            {!szamlazzHu && (
              <Typography variant="body1">
                Digitális faktoring, a leggyorsabb finanszírozási forma
                vállalkozásodnak! Nincs hitel, nincs sorbanállás, digitalizáltuk
                a teljes faktoring <br />
                folyamatot! Te feltöltöd a szükséges adatokat, mi pedig
                megfinanszírozzuk a vevőid felé kiállított számlák bruttó
                értékének max. 90%-át!
                <br />
                Amint a vevőd rendezi felénk a számla ellenértékét, elutaljuk a
                költségeinkkel csökkentett fennmaradó 10%-ot is.
              </Typography>
            )}
            <br />
            <Typography style={{ color: "#125EF2" }} variant="h6">
              {szamlazzHu
                ? "Kérlek válaszd ki mely vevőidre érdekelne finanszírozás, a listában megtalálsz minden vevőt, akinek állítottál ki faktorálható számlát a Számlázz.hu rendszerében az elmúlt 120 napban."
                : "ILYEN EGYSZERŰ! Kíváncsi vagy, hogy ez számokban mennyit jelent? KALKULÁLJUNK!"}
            </Typography>
            {szamlazzHu && (
              <>
                <br />
                <FlexiInfoBar info>
                  Vannak vevők akiket már elő is minősítettünk a gyorsabb
                  ügyintézés érdekében, ezeket a listában jelöltük! Ha több
                  deviza is tartozik egy vevődhoz, kérlek tedd külön igénybe.
                </FlexiInfoBar>
              </>
            )}
          </Grid>
          <Grid xs={12} item>
            <CalculatorForm onSubmit={onSubmit} initialValues={initialValues} />
          </Grid>
        </Grid>
      </Box>
    </StyledPaper>
  );
};

export default Calculator;
