import { TextField } from "final-form-material-ui";
import React from "react";
import {formatNumber, normalizeNumber} from "../../utils/numberUtils";
import { Field } from "react-final-form";
import { InputAdornment } from "@material-ui/core";

const currencyFieldProps = {
  format: formatNumber,
  parse: normalizeNumber
};

const RenderCurrencyField = ({ currency, ...rest }) => {
  const props = {};

  if (currency) {
    props.InputProps = {
      endAdornment: <InputAdornment position="end">{currency}</InputAdornment>
    };
  }

  return <TextField {...rest} {...props} />;
};

export const CurrencyField = props => (
  <Field {...props} {...currencyFieldProps} component={RenderCurrencyField} />
);

export default CurrencyField;
