import PageLayout from "@bit/flexibill.flexi.flexi-page-layout";
import React, { useState, useMemo, useRef, useEffect } from "react";
import FlexiIconStepper from "@bit/flexibill.flexi.flexi-icon-stepper";
import DescriptionIcon from "@material-ui/icons/Description";
import ListIcon from "@material-ui/icons/List";
import SettingsIcon from "@material-ui/icons/Settings";
import CreateIcon from "@material-ui/icons/Create";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import error from "../../images/errorpurple.svg";
import check from "../../images/check.svg";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Grid from "@material-ui/core/Grid";
import {
  useCoworkerData,
  useMutator,
  useNewContractAgreementClaimPost,
  useNewContractPageData,
  useProfileData,
  useSzamlazzhuCustomers
} from "../../hooks/useApi";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, SvgIcon, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import imageSrc from "../../images/smile.svg";
import signIcon from "../../images/redesignImages/signIcon.svg";
import bigGreenCheck from "../../images/redesignImages/green-check-big.svg";
import { Claims } from "./Claims";
import DocumentsPreparationSuccessPaper from "../../components/DocumentsPreparationSuccessPaper";
import Calculator from "./Calculator";
import WelcomePage from "./WelcomePage";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../utils/urlHelpers";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { Fields } from "@bit/flexibill.flexi.flexi-form";
import { Form } from "react-final-form";
import Portal from "@material-ui/core/Portal";
import {
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import ClaimsReview from "./ClaimsReview";
import billSmile from "../../images/redesignImages/Bill.svg";
import billSad from "../../images/bill/3_bill_sad.svg";
import billHappy from "../../images/redesignImages/Bill (1).svg";
import { useParams } from "react-router-dom";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Results from "./Results";
import BillSection from "../../components/BillSection";
import {
  gtagAgreementClaimDocumentsUploaded,
  gtagAgreementClaimOfferAccepted
} from "../../utils/gtag";
import DigitalSignatureDialog, {
  FileUploaderDialog
} from "./DigitalSignatureDialog";
import HBNYRegistrationDialogs from "./HBNYRegistrationDialogs";
import DeclinedDialog from "./DeclinedDialog";
import FormDialog from "./FormDialog";
import CheckIcon from "@material-ui/icons/Check";

//PAYMENT_DONE - FUFF

export const agreementClaimStatusHelper = {
  CUSTOMER_UPLOAD: ["CUSTOMER_UPLOAD"],
  CALCULATION: ["CALCULATION"],
  DOCUMENT_UPLOAD: ["DOCUMENT_UPLOAD"],
  DOCUMENT_REVIEW: [
    "DOCUMENT_REVIEW",
    "PRE_RISK_EVALUATION",
    "RISK_EVALUATION",
    "DECISION",
    "BANK_DECISION",
    "ACCEPTING_FINAL_CALCULATION",
    "CONTRACT_FIXING",
    "CONTRACTING"
  ],
  SIGNATURE: [
    "SIGNATURE",
    "INTERNAL_SIGNATURE",
    "CONTRACT_CONFIGURE",
    "PAYMENT_REVIEW_RISK_EVALUATION",
    "PAYMENT_REVIEW_BACK_OFFICE",
    "PAYMENT"
  ],
  PAYMENT_DONE: ["PAYMENT_DONE", "CLOSED"]
};

export const getAgreementClaimStatus = (
  status,
  array = agreementClaimStatusHelper
) => {
  return Object.keys(array).find(key => array[key].includes(status));
};

export function getAgreementClaimStepperSteps() {
  return [
    {
      label: "Ajánlatkérés",
      status: "CUSTOMER_UPLOAD",
      icon: <DescriptionIcon />
    },
    {
      label: "Előkalkuláció",
      status: "CALCULATION",
      icon: <DescriptionIcon />
    },
    {
      label: "Dokumentumok feltöltése",
      status: "DOCUMENT_UPLOAD",
      icon: <ListIcon />
    },
    {
      label: "Adatok feldolgozása",
      status: "DOCUMENT_REVIEW",
      icon: <SettingsIcon />
    },
    {
      label: "Szerződéskötés",
      status: "SIGNATURE",
      icon: <BusinessCenterIcon />
    },
    {
      label: "Folyósító modul",
      status: "PAYMENT_DONE",
      icon: <ThumbUpIcon />
    }
  ];
}

const useStyles = makeStyles({
  paperRoot: {
    borderRadius: 5,
    border: "1px solid #394AB8",
    padding: "24px",
    marginBottom: 42
  },
  image: {
    width: 60,
    height: 60,
    margin: "auto",
    display: "block",
    marginBottom: 40
  },
  button: {
    backgroundColor: "#BD10E0 !important",
    marginTop: 20,
    margin: "auto",
    display: "block"
  }
});

const GiveAccessDialog = ({ open, onSendClick, onClose }) => {
  const dialogActionsRef = useRef();

  const giveAccessDialogFieldConfig = [
    [
      {
        fieldType: "email",
        name: `email`,
        label: "E-mail",
        required: true
      }
    ]
  ];

  return (
    <FlexiDialog
      title="Hozzáférés küldése"
      open={open}
      onClose={onClose}
      dialogActions={<div ref={dialogActionsRef} />}
    >
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item xs={12}>
          <Typography>
            Adja meg munkatársa e-mail címét és elküldjük neki a linket, amin
            keresztül regisztrálhat és feltöltheti a szükséges dokumentumokat!
          </Typography>
          <br />
          <Form
            onSubmit={onSendClick}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} id={"give-access"}>
                  <Fields config={giveAccessDialogFieldConfig} />
                  <Portal container={dialogActionsRef.current}>
                    <Button onClick={form.submit}>Küldés</Button>
                  </Portal>
                </form>
              );
            }}
          />
          <br />
          <Typography>
            FONTOS: Munkatársa “Megtekintő és dokumentum feltöltő” jogosultságot
            kap. Hozzáférését a “Munkatársak” menüpontban bármikor
            visszavonhatja!
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const CallMeDialog = ({ open, onClose, imgSrc }) => {
  return (
    <FlexiDialog
      title={"Köszönjük érdeklődésed!"}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button onClick={onClose} style={{ margin: "auto" }}>
          Rendben
        </Button>
      }
      imgSrc={imgSrc}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            Munkatársunk hamarosan felveszi veled a kapcsolatot a megadott
            elérhetőségen!
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const WeAreHappyDialog = ({ open, onClose, imgSrc }) => {
  return (
    <FlexiDialog
      title={"Gratulálunk a döntésedhez!"}
      size={"small"}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onClose}>
          Csináljuk!
        </Button>
      }
      imgSrc={imgSrc}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            Örülünk, hogy tetszik az indikatív ajánlatunk! Már csak néhány
            dokumentum feltöltése van hátra.
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const FilesUploadedDialog = ({ open, onClose, onSendClick }) => {
  return (
    <FlexiDialog
      title={"Gratulálunk, mindent sikeresen feltöltöttél!"}
      open={open}
      onClose={onClose}
      imgSrc={billHappy}
      size={"small"}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onSendClick}>
          ELKÜLDÖM
        </Button>
      }
    >
      <Typography>
        Ha ellenőrizni szeretnéd még a dokumentumokat, a jobb sarokban lévő X-re
        kattintva zárd be ezt az ablakot.
      </Typography>
      <Typography>
        Amennyiben megbizonyosodtál a dokumentumok helyességéről, az alábbi
        gombra kattintva elküldheted munkatársunknak!
      </Typography>
    </FlexiDialog>
  );
};

export const useBigButtonStyles = makeStyles({
  root: {
    padding: "20px 50px"
  },
  green: {
    background: "#5DCB9A !important",
    "&:hover": { background: "#3f8767 !important" }
  }
});

const NewContractPage = ({ history }) => {
  const buttonClasses = useBigButtonStyles();
  const [openGiveAccessDialog, setOpenGiveAccessDialog] = useState(false);
  const [openCallMeDialog, setOpenCallMeDialog] = useState(false);
  const [openWeAreHappyDialog, setOpenWeAreHappyDialog] = useState(false);
  const [openFilesUploadedDialog, setOpenFilesUploadedDialog] = useState(false);
  const [openDigitalSignature, setOpenDigitalSignature] = useState(false);
  const [checked, setChecked] = useState(false);
  const steps = getAgreementClaimStepperSteps();
  const { id: agreementClaimIdFromUrl } = useParams();
  const showOnBoardingWelcomePage = !Boolean(agreementClaimIdFromUrl);
  const isSuccess = status =>
    Boolean(status) &&
    status !== "CUSTOMER_UPLOAD" &&
    status !== "CALCULATION" &&
    status !== "DOCUMENT_UPLOAD" &&
    status !== "DOCUMENT_REVIEW" &&
    status !== "ACCEPTING_FINAL_CALCULATION" &&
    status !== "SIGNATURE" &&
    status !== "PAYMENT_DONE";

  const {
    customerAgreementClaims,
    supplierAgreementClaims,
    primaryDocumentTypes,
    secondaryDocumentTypes,
    uploadDocuments,
    uploadSignedDocuments,
    uploadDocumentCopy,
    deleteDocument,
    downloadDocument,
    downloadContract,
    loading,
    agreementClaim,
    reloadAgreementClaim,
    recall,
    finalAccept,
    allFilesUploaded
  } = useNewContractPageData({
    isGuest: false,
    id: agreementClaimIdFromUrl === "new" ? null : agreementClaimIdFromUrl
  });

  useEffect(() => {
    if (allFilesUploaded) {
      setOpenFilesUploadedDialog(true);
    }
  }, [allFilesUploaded]);

  const [
    onNewAgreementClaimClick,
    newAgreementLoading
  ] = useNewContractAgreementClaimPost();

  const notifySuccessSave = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();
  const { sendInvite } = useCoworkerData();
  const [post, postLoading] = useMutator("POST");
  const hasPermission = useHasPermission();
  const { szamlazzHu } = useProfileData();
  const hasSuperAdminPermission = hasPermission(ROLE_FLEXIPAY_SUPER_ADMIN);
  const hasDocumentUploaderRole =
    !hasSuperAdminPermission &&
    hasPermission([ROLE_FLEXIPAY_AGREEMENT_UPLOADER]);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const nextFnOnDocumentUploadPage = async () => {
    const { data, error } = await post(
      getUrlWithCompanyId(
        `/contractAgreementClaim/${agreementClaimIdFromUrl}/submit`
      )
    );
    gtagAgreementClaimDocumentsUploaded();
    if (error) {
      notifyError({ text: error.message });
    }
    if (data) {
      reloadAgreementClaim();
    }
  };

  const rightSideComponents = useMemo(() => {
    if (showOnBoardingWelcomePage) {
      return [
        {
          props: {
            children: "Vágjunk bele!",
            onClick: onNewAgreementClaimClick
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ];
    }
    if (
      agreementClaim?.status === "DOCUMENT_UPLOAD" &&
      !hasDocumentUploaderRole
    ) {
      return [
        // {
        //   props: {
        //     children: (
        //       <>
        //         <svg
        //           width="18"
        //           height="20"
        //           viewBox="0 0 18 20"
        //           xmlns="http://www.w3.org/2000/svg"
        //           style={{ marginRight: "10px" }}
        //         >
        //           <path
        //             d="M11.8271 11H6.17294C4.53629 11.0018 2.96716 11.6839 1.80987 12.8967C0.652584 14.1095 0.00168178 15.7538 0 17.469V19.4452C0 19.5923 0.0557773 19.7335 0.155061 19.8375C0.254345 19.9415 0.389003 20 0.529412 20H17.4706C17.611 20 17.7457 19.9415 17.8449 19.8375C17.9442 19.7335 18 19.5923 18 19.4452V17.469C17.9983 15.7538 17.3474 14.1095 16.1901 12.8967C15.0328 11.6839 13.4637 11.0018 11.8271 11Z"
        //             fill="#02BE6F"
        //           />
        //           <path
        //             d="M9 9C11.7614 9 14 6.98528 14 4.5C14 2.01472 11.7614 0 9 0C6.23858 0 4 2.01472 4 4.5C4 6.98528 6.23858 9 9 9Z"
        //             fill="#02BE6F"
        //           />
        //         </svg>{" "}
        //         Munkatárs tölti fel
        //       </>
        //     ),
        //     variant: "outlined",
        //     onClick: () => setOpenGiveAccessDialog(true),
        //     disabled: hasDocumentUploaderRole
        //   },
        //   mobile: {
        //     placement: "toolbar"
        //   }
        // },
        // {
        //   props: {
        //     children: "Vissza az előkalkulációra",
        //     onClick: () => alert("visszaaaaa")
        //     // disabled: hasDocumentUploaderRole
        //   },
        //   mobile: {
        //     placement: "toolbar"
        //   }
        // },
        {
          props: {
            children: "Mentés",
            onClick: () =>
              notifySuccessSave({
                text:
                  "Ha kilépsz, innen tudod később folytatni, adataid nem vesznek el!"
              })
            // disabled: hasDocumentUploaderRole
          },
          mobile: {
            placement: "toolbar"
          }
        },
        {
          props: {
            children: "Igény benyújtása",
            onClick: nextFnOnDocumentUploadPage,
            disabled: hasDocumentUploaderRole
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ];
    } else {
      return [];
    }
  }, [agreementClaim]);
  const { data: szamlazzHuCustomers } = useSzamlazzhuCustomers(szamlazzHu);
  const stepperValue = getAgreementClaimStatus(agreementClaim?.status);
  const signedDocumentsUploaded = Boolean(
    agreementClaim?.supplier?.signedContractDocument?.length
  );

  useEffect(() => {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [stepperValue]);

  return (
    <>
      <PageLayout
        pay
        loading={loading || postLoading || newAgreementLoading}
        title={
          showOnBoardingWelcomePage
            ? "Üdvözlünk a Digitális Faktoring világában!"
            : steps.find(
                step =>
                  step.status ===
                  (agreementClaimIdFromUrl === "new"
                    ? "CUSTOMER_UPLOAD"
                    : stepperValue)
              )?.label
        }
        suppressTourButton
        disabledBottomMargin
        rightSideComponents={rightSideComponents}
        rightSideComponentsPlacement={"top"}
        backButton
        onBackButtonClick={() => history.push("/app/agreement-claims")}
      >
        <div
          style={
            hasPermission(ROLE_FLEXIPAY_AGREEMENT_UPLOADER) &&
            !hasSuperAdminPermission &&
            agreementClaim?.status !== "DOCUMENT_UPLOAD"
              ? { opacity: "0.6", pointerEvents: "none" }
              : undefined
          }
        >
          <div>
            {showOnBoardingWelcomePage && !loading && (
              <WelcomePage onStartButtonClick={onNewAgreementClaimClick} />
            )}
            {(agreementClaimIdFromUrl === "new" ||
              (!showOnBoardingWelcomePage && agreementClaim)) && (
              <FlexiIconStepper
                items={steps}
                value={stepperValue || "CUSTOMER_UPLOAD"}
              />
            )}
          </div>
          {agreementClaimIdFromUrl === "new" && (
            <div>
              <Calculator
                szamlazzHu={szamlazzHu}
                onSubmit={async values => {
                  if (values.companies.length) {
                    const fullCompanies = values.companies.filter(
                      item => item.party.taxNumber
                    );
                    if (fullCompanies.length !== values.companies.length) {
                      notifyError({
                        text:
                          "Csak a listán feljövő vevőidre indíthatsz új igényt."
                      });
                      return;
                    }
                  }
                  const {
                    data: newContractData,
                    error: newContractError
                  } = await post(
                    getUrlWithCompanyId("/contractAgreementClaim")
                  );

                  if (newContractError) {
                    notifyError({ text: newContractError.message });
                  } else {
                    const { data, error } = await post(
                      getUrlWithCompanyId(
                        `/contractAgreementClaim/${newContractData.id}/customer`
                      ),
                      values.companies.map(company => {
                        return {
                          party: {
                            ...company.party,
                            companyLegalForm:
                              company.localBusiness === "false"
                                ? "009"
                                : company.party.companyLegalForm,
                            localBusiness: company.localBusiness === "true"
                          },
                          calculation: {
                            ...company.calculation,
                            needInsurance:
                              company.calculation.needInsurance === "true"
                          }
                        };
                      })
                    );

                    if (error) {
                      notifyError({ text: error.message });
                    }

                    if (data) {
                      history.push(
                        `/app/agreement-claims/new-contract/${newContractData.id}`
                      );
                    }
                  }
                }}
              />
            </div>
          )}
          {/*{agreementClaimIdFromUrl === "new" &&*/}
          {/*  szamlazzHu &&*/}
          {/*  Boolean(!szamlazzHuCustomers?.length) && (*/}
          {/*    <div style={{ textAlign: "center", paddingTop: "24px" }}>*/}
          {/*      <Typography variant={"h5"}>*/}
          {/*        Minden vevődre elkezdted a szerződéskötést, aki számodra*/}
          {/*        elérhető!*/}
          {/*      </Typography>*/}
          {/*    </div>*/}
          {/*  )}*/}

          {agreementClaim?.status === "CALCULATION" &&
            agreementClaim.customers && (
              <ClaimsReview
                szamlazzHu={szamlazzHu}
                data={agreementClaim}
                showDetailedCalculation={agreementClaim.showDetailedCalculation}
                onCallBackClick={async () => {
                  await recall();
                  setOpenCallMeDialog(true);
                }}
                onNextClick={async () => {
                  const { data, error } = await post(
                    getUrlWithCompanyId(
                      `/contractAgreementClaim/${agreementClaimIdFromUrl}/calculation/accept`
                    )
                  );
                  gtagAgreementClaimOfferAccepted();

                  if (error) {
                    notifyError({ text: error.message });
                  }

                  if (data) {
                    setOpenWeAreHappyDialog(true);
                    reloadAgreementClaim();
                  }
                }}
              />
            )}

          {agreementClaim?.status === "DOCUMENT_UPLOAD" && (
            <React.Fragment>
              <Grid container>
                <Hidden mdUp>
                  <Box mb={2}>
                    <FlexiInfoBar info>
                      <Typography>
                        <b>PRO TIPP: </b>Ezt a lépést gyorsabban elvégezheted,
                        ha számítógépről jelentkezel be!
                      </Typography>
                    </FlexiInfoBar>
                  </Box>
                </Hidden>
                <Grid item xs={12}>
                  <FlexiInfoBar info>
                    Bizonyos adatokat vevőnként kell feltöltened. A fülek
                    segítségével válthatsz a vevőid között. Kérjük, mindenhova
                    töltsd fel a szükséges fájlokat!
                  </FlexiInfoBar>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={"h4"}
                    style={{ paddingBottom: "16px", paddingTop: "16px" }}
                  >
                    Ügyfél adatai
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      style={{
                        height: "72px",
                        justifyContent: "space-between",
                        paddingLeft: "16px",
                        paddingRight: "16px"
                      }}
                    >
                      {checked ? <img src={check} /> : <img src={error} />}
                      <Grid item xs={8} style={{ marginLeft: "-60px" }}>
                        <Grid container direction={"column"}>
                          <Grid
                            item
                            style={{
                              display: "inline-flex"
                            }}
                          >
                            <Typography
                              variant={"h5"}
                              style={{ fontSize: "17px", paddingRight: "8px" }}
                            >
                              Online adatlap
                            </Typography>
                            <Tooltip title={"blabla"}>
                              <SvgIcon style={{ alignSelf: "end" }}>
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                  fill="#2227AC"
                                />
                                <path
                                  d="M9.35381 10.9804L9.24448 11.4274C8.91648 11.5568 8.65448 11.6554 8.45938 11.7231C8.26407 11.7911 8.03713 11.8249 7.77856 11.8249C7.38148 11.8249 7.07263 11.7276 6.85236 11.5344C6.63208 11.3404 6.52191 11.0946 6.52191 10.7964C6.52191 10.681 6.5299 10.5622 6.54652 10.4413C6.56328 10.3202 6.58993 10.1838 6.6264 10.0313L7.03631 8.58045C7.07277 8.44152 7.10377 8.30989 7.1286 8.18541C7.15377 8.0617 7.16591 7.94788 7.16591 7.84542C7.16591 7.66014 7.12755 7.53054 7.05117 7.45767C6.9748 7.38502 6.82907 7.34813 6.61272 7.34813C6.50676 7.34813 6.39785 7.3651 6.28683 7.39792C6.17532 7.43088 6.08002 7.46272 6 7.49232L6.10961 7.04503C6.37814 6.9357 6.63481 6.84208 6.88034 6.76437C7.12586 6.68646 7.35785 6.64747 7.57729 6.64747C7.97163 6.64747 8.27592 6.74277 8.4896 6.93338C8.70329 7.12414 8.8101 7.37141 8.8101 7.67613C8.8101 7.73917 8.80308 7.85026 8.78801 8.00903C8.77328 8.16816 8.74586 8.31396 8.70588 8.44657L8.29773 9.8916C8.26428 10.0077 8.23419 10.1403 8.20803 10.2897C8.18103 10.438 8.16813 10.5513 8.16813 10.6273C8.16813 10.8192 8.21091 10.9502 8.29668 11.0198C8.38308 11.0895 8.53189 11.1241 8.74326 11.1241C8.8425 11.1241 8.95541 11.1065 9.08087 11.0719C9.20598 11.0374 9.29722 11.007 9.35381 10.9804ZM9.45732 4.91379C9.45732 5.16556 9.36244 5.38057 9.17176 5.55737C8.98156 5.7348 8.75238 5.82358 8.48427 5.82358C8.21533 5.82358 7.98558 5.7348 7.79322 5.55737C7.6012 5.3805 7.50498 5.16556 7.50498 4.91379C7.50498 4.66252 7.6012 4.44715 7.79322 4.26811C7.98523 4.08935 8.2154 4 8.48427 4C8.75231 4 8.98156 4.08956 9.17176 4.26811C9.36258 4.44715 9.45732 4.66259 9.45732 4.91379Z"
                                  fill="white"
                                />
                              </SvgIcon>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={"body1"}
                              style={{ color: "#111A29", opacity: 0.5 }}
                            >
                              Még nincsenek kitöltve az adatok. A gombra
                              kattintva töltsd ki az adatlapot!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {checked ? (
                          <Button
                            onClick={() => {
                              setFormDialogOpen(true);
                            }}
                            style={{ width: "180px" }}
                          >
                            <CheckIcon />
                            Kitöltve
                          </Button>
                        ) : (
                          <Button
                            variant={"outlined"}
                            onClick={() => {
                              setFormDialogOpen(true);
                            }}
                            style={{ width: "180px" }}
                          >
                            <CreateIcon />
                            Kitöltés
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Claims
                    items={customerAgreementClaims}
                    onUploadDocuments={values =>
                      uploadDocuments(values, "customer")
                    }
                    documentTypes={primaryDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(values, "customer")
                    }
                    onDocumentDownload={downloadDocument}
                  />
                </Grid>
                <>
                  <Typography
                    variant={"h4"}
                    style={{ paddingBottom: "16px", paddingTop: "16px" }}
                  >
                    Könyvelőnél meglévő adatok
                  </Typography>
                </>
                <Grid item xs={12}>
                  <Paper>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      style={{ paddingLeft: "16px", paddingRight: "16px" }}
                    >
                      <Grid item xs={9}>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <Typography variant={"h4"}>
                              Kérd el a könyvelődtől ezeket a dokumentumokat!
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{ color: "#111A29", opacity: 0.5 }}
                              variant={"body1"}
                            >
                              Az alábbi gombra kattintva jogosultságot adhatsz
                              könyvelődnek, aki elvégezheti az alábbi
                              dokumentumok feltöltését. Ha rendelkezel a
                              dokumentumokkal, Te is feltöltheted!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => setOpenGiveAccessDialog(true)}>
                          Hozzáférés küldése
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <>
                  <Claims
                    items={supplierAgreementClaims}
                    customers={customerAgreementClaims}
                    onUploadDocuments={values => {
                      return uploadDocuments(
                        values,
                        values.type === "customer" ? "customer" : "supplier"
                      );
                    }}
                    documentTypes={secondaryDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(
                        values,
                        values.type === "customer" ? "customer" : "supplier"
                      )
                    }
                    onDocumentDownload={downloadDocument}
                  />
                  <hr style={{ marginTop: 0, marginBottom: "50px" }} />

                  <FilesUploadedDialog
                    open={openFilesUploadedDialog}
                    onClose={() => setOpenFilesUploadedDialog(false)}
                    onSendClick={nextFnOnDocumentUploadPage}
                  />
                  <FormDialog
                    customerId={agreementClaim?.customers[0]?.id}
                    agreementClaim={agreementClaim}
                    supplierId={agreementClaim?.supplier?.id}
                    open={formDialogOpen}
                    onClose={() => setFormDialogOpen(false)}
                    onSubmit={async values => {
                      if (values?.supplier?.overdueTaxDebt === "true") {
                        values.supplier.overdueTaxDebt =
                          values.supplier.overdueTaxDebtAmount;
                      } else {
                        values.supplier.overdueTaxDebt = null;
                      }
                      if (values?.supplier?.electronicMail) {
                        values.supplier.electronicMail = [
                          values.supplier.electronicMail
                        ];
                      }
                      values.customer.forEach(customer => {
                        if (customer?.contact?.electronicMail) {
                          customer.contact.electronicMail = [
                            customer.contact.electronicMail
                          ];
                        }
                        if (
                          customer?.contactForInvoiceConfirmation
                            ?.electronicMail
                        ) {
                          customer.contactForInvoiceConfirmation.electronicMail = [
                            customer.contactForInvoiceConfirmation
                              .electronicMail
                          ];
                        }
                      });

                      await post(
                        getUrlWithCompanyId(
                          `/contractAgreementClaimParty/${agreementClaim?.supplier?.id}/factor-datasheet`
                        ),
                        values.supplier
                      );
                      for (const customerIndex in values.customer) {
                        const customer = values.customer[customerIndex];
                        await post(
                          getUrlWithCompanyId(
                            `/contractAgreementClaimParty/${agreementClaim.customers[customerIndex].id}/factor-datasheet`
                          ),
                          customer
                        );
                      }
                      setFormDialogOpen(false);
                      setChecked(true);
                    }}
                  />
                </>
              </Grid>
            </React.Fragment>
          )}

          {/* Kicsit kusza, a lenyeg hogy csak akkor jelenjen meg ha az Adatok feldolgozasa lepesen van, ami a success.. Refaktor kene.*/}
          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" &&
            !agreementClaim.declined && (
              <Grid
                style={{
                  marginLeft: "118px",
                  marginRight: "118px",
                  marginTop: "80px"
                }}
              >
                <DocumentsPreparationSuccessPaper />
              </Grid>
            )}

          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" && (
            <Results
              agreementClaim={agreementClaim}
              finalAccept={finalAccept}
              recall={recall}
            />
          )}

          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" &&
            agreementClaim.declined && (
              <DeclinedDialog agreementClaim={agreementClaim} />
            )}

          {getAgreementClaimStatus(agreementClaim?.status) === "SIGNATURE" && (
            <Grid container direction={"row"} spacing={6}>
              <Grid item xs={7}>
                <Paper style={{ padding: "60px" }}>
                  <Grid
                    container
                    alignItems={"center"}
                    direction={"column"}
                    spacing={2}
                  >
                    <Grid item>
                      <img
                        alt={"signIcon"}
                        src={signedDocumentsUploaded ? bigGreenCheck : signIcon}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant={"h3"}>
                        {signedDocumentsUploaded
                          ? "Sikeres feltöltés!"
                          : "Elkészült a szerződésed!"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant={"outlined"} onClick={downloadContract}>
                        Letöltés
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={5} container>
                <Grid item>
                  <Typography variant={"h3"}>Gratulálunk,</Typography>
                </Grid>
                {!signedDocumentsUploaded && (
                  <Grid item>
                    <Typography>
                      Már csak egy lépésre vagy attól, hogy a céged is
                      digitálisan faktoráltasson! Egyetlen aláírás kell, és a
                      likviditásod biztos kezekben lesz, hiszen a számláidat a
                      FlexiBill-en bármikor faktoráltathatod.
                      <br />
                      <br />
                      (Ha esetleg van már papíron kötött szerződésed velünk, az
                      érvényes marad, így ezt a lépést kihagyhatod.)
                    </Typography>
                  </Grid>
                )}
                {signedDocumentsUploaded && (
                  <Grid item>
                    <Typography>
                      Sikeresen feltöltötted az aláírt szerződást, így nincs
                      további teendőd! Munkatársunk hamarosan felveszi veled a
                      kapcsolatot/ hamarosan hozzáférhetsz a kibővített ügyfél
                      felületedhez.
                    </Typography>
                  </Grid>
                )}
                {!signedDocumentsUploaded && (
                  <>
                    <Grid
                      item
                      style={{ display: "inline-flex", gap: "0.5rem" }}
                    >
                      <Button onClick={() => setOpenDigitalSignature(true)}>
                        Digitális aláírás
                      </Button>
                      <FileUploaderDialog
                        title={"Aláírt file feltöltése"}
                        description={
                          "Itt töltheted fel a letöltött, beszkennelt és általad aláírt szerződést. A feltöltés mellett kérjük, juttass el hozzánk két példányt személyesen vagy postán (1066 Budapest, Mozsár utca 16.)."
                        }
                        onSubmit={values =>
                          uploadSignedDocuments(values).then(() =>
                            setOpenDigitalSignature(false)
                          )
                        }
                        files={agreementClaim?.supplier.documents.filter(
                          doc =>
                            doc.type === "signed_contract_supplier_document"
                        )}
                      >
                        {({ openDialog }) => {
                          return (
                            <Button onClick={openDialog}>
                              Aláírt fájl feltöltése
                            </Button>
                          );
                        }}
                      </FileUploaderDialog>

                      <DigitalSignatureDialog
                        open={openDigitalSignature}
                        onClose={() => setOpenDigitalSignature(false)}
                        onSubmit={values =>
                          uploadSignedDocuments(values).then(() =>
                            setOpenDigitalSignature(false)
                          )
                        }
                        // files={agreementClaim.supplier.signedContractDocument}
                      />
                    </Grid>
                    <FileUploaderDialog
                      title={"Okmányok feltöltése"}
                      description={
                        <Typography>
                          Itt töltheted fel a szerződéskötéshez szükséges
                          okmányokat (
                          <b>személyi igazolvány, lakcímkártya és adókártya</b>
                          ). Kérlek, az okmányok <b>mindkét oldalát</b>{" "}
                          szkenneld be. Minden papíron szerepeljen, hogy “
                          <b>Az adataim kezeléséhez hozzájárulok</b>”, továbbá
                          az aláírásod és a dátum. Fontos, hogy annak a
                          személynek az okmányait töltsd fel, aki a szerződést
                          aláírja.
                        </Typography>
                      }
                      onSubmit={values => uploadDocumentCopy(values)}
                      files={agreementClaim?.supplier.documents.filter(
                        doc => doc.type === "document_copy"
                      )}
                    >
                      {({ openDialog }) => {
                        return (
                          <Button variant={"outlined"} onClick={openDialog}>
                            Okmányok feltöltése
                          </Button>
                        );
                      }}
                    </FileUploaderDialog>
                    <HBNYRegistrationDialogs
                      agreementClaimId={agreementClaim.id}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {agreementClaim?.status === "PAYMENT_DONE" && (
            <>
              {!agreementClaim.declined && (
                <BillSection
                  title={"Szuper, végig is értél a folyamaton!"}
                  img={billSmile}
                >
                  {szamlazzHu ? (
                    <>
                      <Typography>
                        Egyszerű volt, nem? A szerződést sikeresen aláírtad,
                        most nálunk a labda: elkészítjük neked a személyre szóló
                        felületed, ahol mindent átlátsz és mindent irányíthatsz
                        majd céged digitális faktorálása során.
                        <br />
                        Javasoljuk, hogy a következő számláddal próbáld is ki:
                        miután kiállítottad a számlázz.hu-ban, mi automatikusan
                        betöltjük az ügyfélfelületedre. Itt végig tudod majd
                        követni a teljes folyamatot egészen az utalásig.
                        Onnantól a pénz már nálad van!
                        <br />
                        Örülünk, hogy FlexiBill-es lettél!
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant={"body1"}>
                        Egyszerű volt, nem? A szerződést sikeresen aláírtad,
                        most nálunk a labda: elkészítjük neked a személyre szóló
                        felületed, ahol mindent átlátsz és mindent irányíthatsz
                        majd céged digitális faktorálása során. Ide töltheted
                        majd föl a számlákat, melyek ellenértékét villámgyorsan
                        szeretnél majd megkapni tőlünk.
                      </Typography>
                      <Typography variant={"body1"}>
                        Javasoljuk, hogy a következő számláddal próbáld is ki:
                        kiállítás után töltsd föl hozzánk, mi küldjük a pénzt,
                        Te pedig végig tudod majd követni a teljes folyamatot
                        egészen az utalásig. Onnantól a pénz már nálad van!
                      </Typography>
                      <Typography variant={"body1"}>
                        Örülünk, hogy FlexiBill-es lettél!
                      </Typography>
                    </>
                  )}
                </BillSection>
              )}
            </>
          )}
          {agreementClaim?.status === "CLOSED" && (
            <BillSection
              img={billSad}
              title={"A szerződéses igényed lezárásra került"}
            >
              Új szerződéses igény esetén kérlek vedd fel velünk a kapcsolatot,
              vagy indítsd el digitálisan az új igény gombra kattintva.
              <Button
                onClick={() => {
                  history.push("/external-sales/agreement-claims/new-contract");
                }}
              >
                Új igény
              </Button>
            </BillSection>
          )}
          {/*</div>*/}
          <GiveAccessDialog
            open={openGiveAccessDialog}
            onClose={() => setOpenGiveAccessDialog(false)}
            onSendClick={async values => {
              const { data: successResponse, error } = await sendInvite({
                ...values,
                roles: [ROLE_FLEXIPAY_AGREEMENT_UPLOADER]
              });

              if (successResponse) {
                notifySuccessSave({ text: "Meghívó elküldve!" });
                setOpenGiveAccessDialog(false);
              }
              if (error) {
                notifyError({ text: error.message });
              }
            }}
          />
          <CallMeDialog
            open={openCallMeDialog}
            onClose={() => setOpenCallMeDialog(false)}
            imgSrc={billSmile}
          />
          <WeAreHappyDialog
            open={openWeAreHappyDialog}
            onClose={() => setOpenWeAreHappyDialog(false)}
            imgSrc={billHappy}
          />
        </div>
      </PageLayout>
    </>
  );
};

export default NewContractPage;
