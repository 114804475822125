export const normalizeNumber = val => {
  const normalized = val
    .toString()
    .replace(/\s/g, "")
    .replace(/,/g, ".");

  if (alreadyContainsSeparator(normalized) && endsWithSeparator(normalized)) {
    return val.slice(0, -1);
  }

  return normalized;
};

const separators = [".", ","];

const endsWithSeparator = value =>
  separators.reduce(
    (endsWith, separator) =>
      endsWith || value.toString().lastIndexOf(separator) === value.length - 1,
    false
  );

const onlyDash = value => value.length === 1 && value[0] === "-";

const endsWithBullshit = value => {
  const stringValue = value.toString();
  const lastChar = stringValue[stringValue.length - 1];
  return !/([0-9,. ])/.test(lastChar);
};

const alreadyContainsSeparator = value =>
  separators.reduce((endsWith, separator) => {
    const separatorIndex = value.toString().indexOf(separator);

    return (
      endsWith || (separatorIndex !== value.length - 1 && separatorIndex !== -1)
    );
  }, false);

export const formatNumber = input => {
  if (input !== 0 && !input) return "";

  if (
    (alreadyContainsSeparator(input) && endsWithSeparator(input)) ||
    (!onlyDash(input) && endsWithBullshit(input))
  ) {
    return input.slice(0, -1);
  }

  if (endsWithSeparator(input) || onlyDash(input)) {
    return input;
  }

  const cleanedInput = normalizeNumber(input);
  const convertedInput = new Intl.NumberFormat("hu-HU").format(cleanedInput);

  return convertedInput;
};

export const parseNumber = val => {
  if (val === undefined) {
    throw new Error("parseNumber got undefined instead of formatted number");
  }

  return parseFloat(normalizeNumber(val));
};
