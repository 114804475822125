import React from "react";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const FlexiCard = ({ card }) => {
  return (
    <Paper
      style={{
        width: "163px",
        height: "280px",
        padding: "24px 16px 20px"
      }}
    >
      <Grid container direction={"column"}>
        <Grid item style={{ paddingBottom: "12px" }}>
          <Typography
            variant={"h4"}
            style={{ color: "#125EF2", fontWeight: "bold" }}
          >
            {card.cardNumber}
          </Typography>
        </Grid>
        <Grid item style={{ paddingBottom: "12px" }}>
          <img alt={"img"} src={card.img} />
        </Grid>
        <Grid item style={{ paddingBottom: "8px" }}>
          <Typography variant={"body2"} style={{ fontWeight: "bold" }}>
            {card.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"body1"}>{card.text}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default FlexiCard;
