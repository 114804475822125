import ErrorBoundary from "../../components/ErrorBoundary";
import FlexiHorizontalBarChart from "@bit/flexibill.flexi.flexi-horizontal-bar-chart";
import { ROLE_FLEXIPAY_INVOICE_MANAGER } from "../../roles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import horizontalPlaceHolder from "../../images/onboarding_dash_keret.svg";
import React, { useEffect, useState } from "react";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { useHistory } from "react-router-dom";
import { Badge, Box, Button, ButtonGroup, makeStyles } from "@material-ui/core";
import walletIcon from "../../images/redesignImages/wallet.svg";
import cashierMachineIcon from "../../images/redesignImages/cashier-machine.svg";
import infoIcon from "../../images/redesignImages/info-icon.svg";
import ContractList from "../FactorContractsPage/ContractList";
import { formatCurrency } from "@bit/flexibill.flexi.flexi-currency-formatter";
import { useAgreements } from "../../hooks/useApi";

const Helper = () => {
  return (
    <Paper
      elevation={0}
      style={{
        // border: "1px solid gray",
        paddingLeft: "1rem",
        backgroundColor: "rgb(244 247 254)",
        height: "100%",
        display: "flex"
      }}
    >
      <Box display={"flex"} alignItems={"center"} style={{ gap: "19px" }}>
        <img src={infoIcon} />
        <Typography>
          Itt láthatod, hogy a vevődre mennyi finanszírozást kaptál (adott
          előleg) és mennyi van még a keretedből.
        </Typography>
      </Box>
    </Paper>
  );
};

const IconItem = ({ title, iconSrc, content }) => {
  return (
    <Paper
      elevation={0}
      style={{
        border: "1px solid gray",
        padding: "1rem",
        height: "calc(100% - 2rem)"
      }}
    >
      <Box display={"flex"} style={{ gap: "19px" }}>
        <img src={iconSrc} />
        <div>
          <Typography>{title}</Typography>
          <Typography variant={"h5"}>{content}</Typography>
        </div>
      </Box>
    </Paper>
  );
};

const DashboardLimitsChart = ({
  agreementStat,
  szamlazzHu,
  kulcsSoft,
  onClick
}) => {
  const hasPermission = useHasPermission();
  const { push: redirect } = useHistory();
  const useActiveClasses = makeStyles({
    root: {
      backgroundColor: "#2227AC !important",
      "& .MuiBadge-badge": {
        backgroundColor: props =>
          props.szamlazzHu ? "#FF6630 !important" : "#F84F96 !important"
      }
    }
  });
  const useActiveSzhuClasses = makeStyles({
    root: {
      backgroundColor: "#2227AC !important",
      "& .MuiBadge-badge": {
        backgroundColor: "#FF6630 !important"
      }
    }
  });
  const useInactiveClasses = makeStyles({
    root: {
      "& .MuiBadge-badge": {
        backgroundColor: "white !important",
        color: "#02BE6F !important"
      }
    }
  });

  const activeClasses = useActiveClasses();
  const activeSzhuClasses = useActiveSzhuClasses();
  const inactiveClasses = useInactiveClasses();
  const { data: agreements } = useAgreements();
  const hufAgreements = agreements?.filter(item => item.currency === "HUF");
  const eurAgreements = agreements?.filter(item => item.currency === "EUR");
  const usdAgreements = agreements?.filter(item => item.currency === "USD");
  const [selectedCurrency, setSelectedCurrency] = useState("HUF");
  useEffect(() => {
    if (hufAgreements && !hufAgreements.length) {
      setSelectedCurrency("EUR");
    }
  }, [hufAgreements?.length]);
  return (
    <Paper
      style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "40px" }}
    >
      <Box display={"flex"} flexDirection={"column"} style={{ gap: "2rem" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant={"h5"} style={{ paddingBottom: "8px" }}>
              Keretkihasználtság áttekintése
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "8px" }}>
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button
                classes={
                  selectedCurrency === "HUF"
                    ? szamlazzHu
                      ? activeSzhuClasses
                      : activeClasses
                    : inactiveClasses
                }
                onClick={() => setSelectedCurrency("HUF")}
              >
                HUF &nbsp;&nbsp; <Badge badgeContent={hufAgreements?.length} />
              </Button>
              <Button
                classes={
                  selectedCurrency === "EUR"
                    ? szamlazzHu
                      ? activeSzhuClasses
                      : activeClasses
                    : inactiveClasses
                }
                onClick={() => setSelectedCurrency("EUR")}
              >
                EUR &nbsp;&nbsp;
                <Badge badgeContent={eurAgreements?.length} />
              </Button>
              <Button
                classes={
                  selectedCurrency === "USD"
                    ? szamlazzHu
                      ? activeSzhuClasses
                      : activeClasses
                    : inactiveClasses
                }
                onClick={() => setSelectedCurrency("USD")}
              >
                USD &nbsp;&nbsp;
                <Badge badgeContent={usdAgreements?.length} />
              </Button>
            </ButtonGroup>
          </Grid>
          {!szamlazzHu ||
            (!kulcsSoft && (
              <Grid item>
                <Button onClick={onClick}>Számla feltöltése</Button>
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Helper />
          </Grid>
          <Grid item xs={6} sm={4}>
            <IconItem
              title={"Adott előleg összege"}
              iconSrc={walletIcon}
              content={formatCurrency(
                agreementStat?.total[selectedCurrency]?.all || 0,
                { currency: selectedCurrency }
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <IconItem
              title={"Lehívható előleg összege"}
              iconSrc={cashierMachineIcon}
              content={formatCurrency(
                agreementStat?.total[selectedCurrency]?.remain || 0,
                { currency: selectedCurrency }
              )}
            />
          </Grid>
        </Grid>
        <ContractList
          localItems={
            selectedCurrency === "HUF"
              ? hufAgreements
              : selectedCurrency === "EUR"
              ? eurAgreements
              : usdAgreements
          }
          hideSearchBar
        />
      </Box>
    </Paper>
  );
};

export default DashboardLimitsChart;
