import React from "react";
import bill from "../../images/redesignImages/Bill.svg";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withWidth from "@material-ui/core/withWidth";
import FlexiCard from "../../components/FlexiCard";
import cardImage1 from "../../images/redesignImages/Kalkulátor.svg";
import cardImage2 from "../../images/redesignImages/Mappa.svg";
import cardImage3 from "../../images/redesignImages/Nagyító.svg";
import cardImage4 from "../../images/redesignImages/Group 31.svg";
import cardImage5 from "../../images/redesignImages/Pénz.svg";
import BillSection from "../../components/BillSection";
const WelcomePage = ({ onStartButtonClick }) => {
  const cards = [
    {
      cardNumber: "01",
      img: cardImage1,
      title: "Azonnali személyre szabott indikatív ajánlat",
      text:
        "Néhány céges infó megadása után már láthatod is a faktorálás becsült díját."
    },
    {
      cardNumber: "02",
      img: cardImage2,
      title: "Dokumentáció",
      text:
        "Amennyiben tetszik a számítás, elkezdheted feltölteni a vállalkozásod és vevőd adatait!"
    },
    {
      cardNumber: "03",
      img: cardImage3,
      title: "Bevizsgálás",
      text:
        "Ezt követően alaposan felmérjük, hogy meg tudjuk-e előlegezni a számláidat."
    },
    {
      cardNumber: "04",
      img: cardImage4,
      title: "Szerződéskötés",
      text:
        "Kényelmesen, akár digitális aláírással is megkötheted a szerződést"
    },
    {
      cardNumber: "05",
      img: cardImage5,
      title: "Számlabenyújtás és folyosítás",
      text:
        "Ennyi! Ha kiállítasz egy számlát, töltsd fel és mi utaljuk a pénzed!"
    }
  ];

  return (
    <div style={{ width: "100%", marginBottom: "1rem" }}>
      <Grid container spacing={2}>
        {cards.map(card => (
          <Grid item xs={false}>
            <FlexiCard card={card} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <BillSection title={"Ugye, milyen egyszerű?"} img={bill}>
            <Button onClick={onStartButtonClick}>Igen, vágjunk bele!</Button>
          </BillSection>
        </Grid>
      </Grid>
    </div>
  );
};

export default withWidth()(WelcomePage);
